// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { ECLClientCard } from "../../components/ecl/ECLClientCard";
import { useNavigate } from "react-router-dom";
import { Center, Client, Pod, EDPNAnswerObject, VwLateEcldpnreason } from "../../utils/Definitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Select from "react-select";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalNotification } from "../../components/ModalNotification";
import { ModalConfirm } from "../../components/ModalConfirm"
import { ModalAddLateNote } from "../../components/ModalAddLateNote";
import { zonedTimeToUtc } from 'date-fns-tz'
import { EDPNContext } from "./EDPNContext";
import { EDPNClientCard } from "../../components/edpn/EDPNClientCard";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { CredentialsContext } from "../credentials/CredentialsContext";


//only renders if authenticated
const EDPNHomeContent = () => {


    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);
    const credentialsContext = useContext(CredentialsContext);

    const [unapprovedLogs, setUnapprovedLogs] = useState<EDPNAnswerObject[]>([]);
    const [correctionsCount, setCorrectionsCount] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(true);


    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);
    const [credentialsRBTModalActive, setCredentialsRBTModalActive] = useState<boolean>(false);

    const [addLateNoteModalActive, setAddLateNoteModalActive] = useState<boolean>(false);

    useEffect(() => {
        if (edpnContext.clients && edpnContext.clients.length > 0) {
            setLoading(false);
        }
        else {
            setLoading(true);
        }
    }, [edpnContext.clients]);

    useEffect(() => {
        GetCorrectionCount();

    }, [edpnContext.selectedCenter]);

    function GetCorrectionCount() {

        globalContext.GetToken().then((token: any) => {
            if (edpnContext.selectedCenter && edpnContext.selectedCenter.centerId) {
                setLoading(true);

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = "";

                if (globalContext.CoreAccount.currentUser?.therapistID) {
                    url = apiConfig.apiEndpoint + "/EDPN/GetCorrectionsCount?centerid=" + edpnContext.selectedCenter.centerId + "&therapistId=" + globalContext.CoreAccount.currentUser?.therapistID + "&imported=" + false + "&monthday=" + new Date()?.toDateString();
                }
                else {
                    url = apiConfig.apiEndpoint + "/EDPN/GetCorrectionsCount?centerid=" + edpnContext.selectedCenter.centerId + "&imported=" + false + "&monthday=" + new Date()?.toDateString();
                }

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setCorrectionsCount(result);
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetCorrectionsCount" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }

        })
    }

    function NavigateBack() {
        navigate("/edpn");
    }

    function NavigateToApprovals() {
        navigate("/edpn/approvals");
    }

    function NavigateToApprovals2() {
        navigate("/edpn/approvals2");
    }

    function NavigateToGroup() {
        edpnContext.setShowInactiveClients(false);
        navigate("/edpn/groupcreate");
    }

    function OpenAddLateNoteModal() {
        setAddLateNoteModalActive(true);
    }

    function HandleClientFilterChange(event: { target: { value: string; }; }) {
        edpnContext.setClientFilter(event.target.value);
    }

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }

    function CredentialsRBTModalConfirm() {
        navigate("/edpn/addlog");
    }


    if (!edpnContext.groupEditNavigating) {
        return (

            <div className="grid grid-rows-auto ml-auto mr-auto">

                <div className="grid grid-cols-1 h-fit mt-0 mb-8">


                    <div className="grid grid-flow-row">

                        <div className="mt-4 mb-4 mx-2 ml-auto">

                            <ProtectedComponent RequiredRoles={['AddLateNote']}>
                                <button onClick={OpenAddLateNoteModal} className="bg-rose mx-5 w-[10rem] h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600"><span className="drop-shadow">Add Late Note</span></button>
                            </ProtectedComponent>

                            <ProtectedComponent RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                                <button onClick={NavigateToGroup} className="bg-purple mx-5 w-[12rem] h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"><span className="drop-shadow">Add Group Note</span></button>
                            </ProtectedComponent>

                            <ProtectedComponent RequiredRoles={['EDPN.Approve', 'EDPN.Approvals.View']}>
                                <button onClick={NavigateToApprovals2} className="bg-lacgreen mx-5 w-[14rem] h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"><span className="drop-shadow">Approvals/Corrections</span></button>
                            </ProtectedComponent>

                            <ProtectedComponent RequiredRoles={['EDPN.Write']}>
                                <button onClick={NavigateToApprovals} className="bg-lacyellow mx-5 w-[12rem] h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacyellow-600"><span className="drop-shadow">My Corrections ({correctionsCount})</span></button>
                            </ProtectedComponent>

                        </div>

                        <div className="grid grid-cols-1 mt-4 gap-4 xl:gap-[4.5rem] content-between xl:grid-flow-col xl:grid-cols-4 xl:w-fit xl:m-auto">

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="search">
                                    Search:
                                </label>
                                <input autoComplete="off" onChange={HandleClientFilterChange} type="text" id="search" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" placeholder="Search..." />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="centers">
                                    Center:
                                </label>
                                <Select options={globalContext.lockdownCenters} onChange={edpnContext.setSelectedCenter} value={edpnContext.selectedCenter} />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto">
                                <label className="block font-bold" htmlFor="pods">
                                    Pod:
                                </label>
                                <Select options={edpnContext.filteredPods ? edpnContext.filteredPods : undefined} onChange={edpnContext.setSelectedPod} value={edpnContext.selectedPod} />
                            </div>

                            <div className="w-full xl:w-[16rem] m-auto xl:mt-auto xl:mb-1">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={event => edpnContext.setShowInactiveClients(event.target.checked)} checked={edpnContext.showInactiveClients} className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                    <span className="ml-3 text-sm font-medium">Show All Learners</span>
                                </label>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-6 place-items-center xl:grid-cols-2 w-fit m-auto mt-8">
                            {edpnContext.clientsFiltered ? edpnContext.clientsFiltered.map(client => <EDPNClientCard SetProfilePictureModalActive={setProfilePictureModalActive} key={client.clientId} Client={client} SetSelectedClientForModal={setSelectedClientForModal} SetCredentialsModalActive={setCredentialsModalActive} setCredentialsRBTModalActive={setCredentialsRBTModalActive} />) : <></>}
                        </div>
                    </div>

                </div>

                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalAddLateNote isActive={addLateNoteModalActive} setActive={setAddLateNoteModalActive} isEDPN={true} cptCodes={edpnContext.CPTCodes} clients={edpnContext.clients} />

                <ModalNotification Title={"Warning!"} Body={"<div>You must fill out your credentials and signature to modify a log.</div><div>1. Navigate to the top right of your screen</div><div>2. Click on the menu button.</div><div>3. Click on 'Edit Profile'.</div>"} Active={credentialsModalActive} Icon={"Warning"} setActive={setCredentialsModalActive} OnModalClose={function noop() { }} />
                <ModalConfirm Message={"<div>This learner requires an RBT to bill for services.</div><div>If you're an RBT, update your credential. If not, STOP and consult your CA for guidance</div>"} Active={credentialsRBTModalActive} setActive={setCredentialsRBTModalActive} OnConfirm={CredentialsRBTModalConfirm} />

            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }



};


export function EDPNHome() {

    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Read', 'EDPN.Write', 'EDPN.Approve']}>
                    <EDPNHomeContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};