// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { GrammarContext, GrammarContextProvider } from "../../utils/GrammarContext";
import { CredentialsContext } from "../credentials/CredentialsContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { EDPNContext } from "./EDPNContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";

import { addDays, format, isAfter, isBefore, isMonday, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { ClassNames } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { time } from "console";
import { timingSafeEqual } from "crypto";
import { ModalNotification } from "../../components/ModalNotification";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { PopperGrammarBoxes } from "../../components/PopperGrammarBoxes";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { AbsentReason, APIReturnObject, Client, ECLAnswerObject, EDPNAnswer, EDPNAnswerObject, EDPNQuestion, FutureTimeObject, Status, ScheduleEvent, Schedule, CPTCode } from "../../utils/Definitions";
import { EDPNQuestionNode } from "../../components/edpn/EDPNQuestionNode";

//AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE 
//AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE 
//AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE 
//AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE 
//AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE 
//AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE 
//AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE AUTOSAVE WAS A MISTAKE 

//only renders if authenticated
const EDPNAddLogContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);

    const grammarContext = useContext(GrammarContext);

    const credentialsContext = useContext(CredentialsContext);

    const [grammarMistakeModalActive, setGrammarMistakeModalActive] = useState<boolean>(false);

    const [noteIncomplete, setNoteIncomplete] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(true);
    const [addNoteLoading, setAddNoteLoading] = useState<boolean>(false);

    const [answerObject, setAnswerObject] = useState<EDPNAnswerObject | null>(null);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);

    const [timeIn, setTimeIn] = useState<Date | null>(null);
    const [timeInText, setTimeInText] = useState<string>("");

    const [timeOut, setTimeOut] = useState<Date | null>(null);
    const [timeOutText, setTimeOutText] = useState<string>("");

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [validationModalActive, setValidationModalActive] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>("");

    const [antecedentConfirmationModalActive, setAntecedentConfirmationModalActive] = useState<boolean>(false);

    const [submitNoteConfirmationModalActive, setSubmitNoteConfirmationModalActive] = useState<boolean>(false);
    const [submitNoteConfirmationMessage, setSubmitNoteConfirmationMessage] = useState<string>("");

    const [saveNoteConfirmationModalActive, setSaveNoteConfirmationModalActive] = useState<boolean>(false);
    const [saveNoteConfirmationMessage, setSaveNoteConfirmationMessage] = useState<string>("");

    const [questions, setQuestions] = useState<EDPNQuestion[]>();
    var tempFlatQuestions: EDPNQuestion[] = [];
    const [flatQuestions, setFlatQuestions] = useState<EDPNQuestion[]>([]);

    const [filteredQuestions, setFilteredQuestions] = useState<EDPNQuestion[]>();

    const [selectedReason, setSelectedReason] = useState<AbsentReason | null>();

    const [billingDaySchedule, setBillingDaySchedule] = useState<Schedule | null>();
    const [isAutosaving, setIsAutoSaving] = useState<boolean>(false);

    const [parentConsentTelehealth, setParentConsentTelehealth] = useState<boolean | null>(null);

    const [FilteredCPTCodes, setFilteredCPTCodes] = useState<CPTCode[] | null>();

    //97156 ONLY SHOW UP FOR IOWA STATES + EAST MOLINE
    useEffect(() => {
        if (edpnContext.CPTCodes) {

            // filter out 97156
            var tempcodes: CPTCode[] = edpnContext.CPTCodes.filter(x => x.value != "97156");

            //add 97156 for IOWA and EAST MOLINE ONLY
            if (edpnContext.selectedCenter && (edpnContext.selectedCenter.state == "IA" || edpnContext.selectedCenter.centerId == 48)) {
                edpnContext.CPTCodes.filter(x => x.value == "97156").forEach(x => tempcodes.push(x));
            }

            setFilteredCPTCodes(tempcodes);
        }

    }, [edpnContext.CPTCodes]);


    useEffect(() => {

        console.log("addNoteLoading", addNoteLoading);
    }, [addNoteLoading]);

    useEffect(() => {

        console.log("isAutosaving", isAutosaving);
    }, [isAutosaving]);

    useEffect(() => {
        if (!edpnContext.selectedClient) {
            navigate("/edpn");
        }
    })

    useEffect(() => {

        if (edpnContext.isViewOnly) {
            edpnContext.setIsViewOnly(false);
        }

        if (edpnContext.selectedEditAnswer) {
            edpnContext.setSelectedEditAnswer(null);
        }
    }, []);

    useEffect(() => {
        console.log("edpn add log cptcodes:", FilteredCPTCodes);
        if (FilteredCPTCodes && FilteredCPTCodes.length > 0) {
            edpnContext.setSelectedCPTCode(FilteredCPTCodes[0]);
        }
    }, [FilteredCPTCodes])

    useEffect(() => {
        if (edpnContext.servicePlaces && edpnContext.servicePlaces.length > 0) {
            edpnContext.setSelectedServicePlace(edpnContext.servicePlaces[0]);
        }
    }, [edpnContext.servicePlaces])

    useEffect(() => {
        UpdateQuestions();
    }, []);

    useEffect(() => {
        UpdateNoteComplete();
    }, [timeIn, timeOut, answerObject])

    useEffect(() => {
        //AddNote(false);
    }, [timeIn, timeOut])

    useEffect(() => {
        if (answerObject) {
            console.log("answerObject:", answerObject);
        }
    }, [answerObject])

    useEffect(() => {
        if (edpnContext.selectedClient) {
            GetSchdedule();
        }
    }, []);

    useEffect(() => {

        if (questions) {

            //reset tempflatquestions, then regenerate them.
            tempFlatQuestions = [];

            GenerateFlatQuestions(questions);

            setFlatQuestions(tempFlatQuestions);
        }

    }, [questions]);

    useEffect(() => {

        if (edpnContext.selectedCPTCode && questions) {
            if (questions) {
                setFilteredQuestions(questions.filter(question => String(question.cptcode) == String(edpnContext.selectedCPTCode?.value)));
            }
        }
    }, [edpnContext.selectedCPTCode, questions]);

    function GenerateFlatQuestions(questions: EDPNQuestion[]) {
        tempFlatQuestions = tempFlatQuestions.concat(questions);

        questions.forEach((q) => {
            if (Array.isArray(q.children)) {
                GenerateFlatQuestions(q.children);
            }
        })

    }

    function GetSchdedule() {
        var today = new Date;
        var dayID = today.getDay() + 1;

        var schedule = credentialsContext.schedules.find(x => x.therapyScheduleId == edpnContext.selectedClient?.therapyScheduleId && x.dayOfWeekId == dayID);

        if (schedule) {
            setBillingDaySchedule(schedule);
        }
    }

    function NavigateBack() {
        navigate("/edpn");
    }


    function TimeInValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeInText = timeInText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeInText, "h:mm a", new Date());
        var d2 = parse(tempTimeInText, "h:mma", new Date());
        var d3 = parse(tempTimeInText, "hmma", new Date());
        var d4 = parse(tempTimeInText, "h a", new Date());
        var d5 = parse(tempTimeInText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeInText != dformatted) {
                    setTimeInText(dformatted);
                }

                //check for negative time
                if ((timeOut && isBefore(d, timeOut)) || (SiblingDate && isBefore(d, SiblingDate))) {
                    //update time in
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else if (!timeOut) {
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else {
                    setTimeIn(null);
                }

            }
        }
        else {
            setTimeIn(null);
        }
    }

    function TimeOutValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeOutText = timeOutText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeOutText, "h:mm a", new Date());
        var d2 = parse(tempTimeOutText, "h:mma", new Date());
        var d3 = parse(tempTimeOutText, "hmma", new Date());
        var d4 = parse(tempTimeOutText, "h a", new Date());
        var d5 = parse(tempTimeOutText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeOutText != dformatted) {
                    setTimeOutText(dformatted);
                }

                //check for negative time
                if ((timeIn && isAfter(d, timeIn)) || (SiblingDate && isAfter(d, SiblingDate))) {
                    //update time out
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else if (!timeIn) {
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else {

                    setTimeOut(null);
                }
            }
        }
        else {
            setTimeOut(null);
        }
    }

    async function NoteValidation(token: string, edpnanswerobject: EDPNAnswerObject | null, isComplete: boolean) {

        if (!isComplete) {
            //autosave ignore validation
            return false;
        }
        else {
            if (edpnanswerobject) {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(edpnanswerobject)
                };

                const response = await fetch(apiConfig.apiEndpoint + "/EDPN/NoteValidation", options);
                var result: APIReturnObject = await response.json();
                if (result.status === Status.SUCCESS) {
                    return false;
                }
                else if (result.status === Status.FAIL) {
                    setValidationMessage(result.message ? result.message : "Unknown error.");
                    setValidationModalActive(true);
                    return true;
                }
                else if (result.status === Status.ERROR) {
                    globalContext.setErrorModalProps({ errorMessage: result.message, errorLocation: "/EDPN/NoteValidation" });
                    globalContext.setErrorModalActive(true);
                    return true;
                }
            }
            else {
                return true;
            }
        }
        
    }

    function PreSubmissionGrammarCheck() {
        // Do the call to get the grammar mistakes here
        setAddNoteLoading(true);
        grammarContext.CheckAllGrammar().then((hasMistakes: boolean) => {
            if (hasMistakes) {
                setGrammarMistakeModalActive(true);
            }
            else {
                AntecedentStrategiesCheck();
            }
            setAddNoteLoading(false);
        });
    }

    function AntecedentStrategiesCheck() {
        //notify when less than 2 antecedent strategies are selected

        var AntecedentQuestions: EDPNQuestion[] = flatQuestions.filter(x => x.label == "Antecedent Strategies" && x.type == "multichoice");

        var found: EDPNAnswer | undefined = answerObject?.answers?.find(answer =>
            AntecedentQuestions.some(question => question.questionId === answer.questionId)
        );

        if (found && found.textValue && found.textValue.split(",").length < 2) {
            console.log("LESS THAN 2 ANTECEDENTS");
            setAntecedentConfirmationModalActive(true);
        }
        else {
            SubmitNoteCheck();
        }
    }

    function SubmitNoteCheck() {

        //show the confirmation modal and add note if confirm is pressed
        if (edpnContext.selectedClient) {
            var text = "Add note for <strong>" + edpnContext.selectedClient.fullName + "</strong>";
            if (timeInText.length > 0) {
                text += " with a start time of <strong>" + timeInText + "</strong>";
            }
            else {
                text += " with no start time";
            }

            if (timeOutText.length > 0) {
                text += " and with an end time of <strong>" + timeOutText + "</strong>";
            }
            else {
                text += " and with no end time";
            }

            text += "?<div></div>";
            text += "<strong>Please remember to graph your sessions in central reach.</strong>";

            setSubmitNoteConfirmationMessage(text);
            setSubmitNoteConfirmationModalActive(true);
        }
    }

    // AUTO REFRESH EVERY 1/2 sec for autosave
    useEffect(() => {
        const intervalId = setInterval(() => {

            //if autosave flag is set, and we are not currently autosaving, autosave
            if (!isAutosaving && edpnContext.autosaveFlag) {
                console.log("Autosaving");
                edpnContext.setAutosaveFlag(false);
                AddNote(false);
            }

        }, (0.5 * 1000)); // 0.5 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    });

    function AddNote(isComplete: boolean) {
        if (answerObject?.answers?.length == 0) {
            console.log("NOTE CORRUPTION DETECTED, REJECTING SAVE");
            //globalContext.setErrorModalProps({ errorMessage: "Note corruption detected! Save was prevented to avoid corruption. Please navigate out and back into the log and try again. If this error continues, please contact I.T.", errorLocation: "/EDPN/UpdateEDPN" });
            //globalContext.setErrorModalActive(true);
            return;
        }

        setIsAutoSaving(true);

        // Set created date
        if (isComplete) {
            setAddNoteLoading(true);
        }

        var finalAnswerObject = answerObject;

        if (answerObject && finalAnswerObject) {
            if (edpnContext.selectedCPTCode && edpnContext.selectedCPTCode.value) {
                finalAnswerObject.cptcode = edpnContext.selectedCPTCode.value;
            }

            if (!finalAnswerObject.createdDate) {
                finalAnswerObject.createdDate = new Date(new Date().toLocaleString() + " +0000");
            }

            if (!finalAnswerObject.billingDate) {
                finalAnswerObject.billingDate = new Date();
            }

            if (timeIn) {
                finalAnswerObject.startTime = new Date(timeIn.toLocaleString() + " +0000");
            }

            if (timeOut) {
                finalAnswerObject.endTime = new Date(timeOut.toLocaleString() + " +0000");
            }

            if (edpnContext.selectedServicePlace) {
                finalAnswerObject.placeOfServiceId = Number(edpnContext.selectedServicePlace.value);
            }

            if (edpnContext.selectedClient && edpnContext.selectedClient.centerId) {
                finalAnswerObject.centerId = edpnContext.selectedClient.centerId;
            }

            if (edpnContext.selectedClient && edpnContext.selectedClient.podId) {
                finalAnswerObject.podId = edpnContext.selectedClient.podId;
            }

            if (selectedReason) {
                finalAnswerObject.tardyReasonId = selectedReason.id;
            }

            if (isComplete) {
                finalAnswerObject.dpnstatusId = 4;
                console.log("note incomplete: 4");
            }
            else {
                finalAnswerObject.dpnstatusId = 6;
                console.log("note incomplete: 6");
            }
        }

        globalContext.GetToken().then((token: any) => {
            NoteValidation(token, finalAnswerObject, isComplete).then((errors) => {
                if (errors) {
                    setIsAutoSaving(false);
                    setAddNoteLoading(false);
                }
                else {
                    var headers = new Headers();
                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append("Content-type", "application/json;charset=UTF-8");

                    var options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(finalAnswerObject),
                    };

                    const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length;
                    const kiloBytes = size / 1024;

                    console.log("finalanswerobject sent to api:", finalAnswerObject);

                    var url = apiConfig.apiEndpoint + "/EDPN/UpdateEDPN";

                    // Timeout setup
                    const controller = new AbortController();
                    const timeoutId = setTimeout(() => controller.abort(), 20000); // 20 seconds

                    fetch(url, { ...options, signal: controller.signal })
                        .then((res) => res.json())
                        .then(
                            (result: APIReturnObject) => {
                                clearTimeout(timeoutId);
                                console.log("api result:", result);
                                if (result.status === Status.SUCCESS) {
                                    if (isComplete) {
                                        setAddNoteLoading(false);
                                        setSuccessModalActive(true);
                                        console.log("regular save");
                                    }
                                    else {
                                        if (result.message) {
                                            const newLogObject = JSON.parse(result.message);
                                            console.log("Autosaved:", newLogObject);

                                            globalContext.setToastMessage("Autosave Successful");
                                            globalContext.setToastIcon("Success");
                                            globalContext.setToastActive(true);

                                            const oldLogObject = answerObject;

                                            if (oldLogObject && newLogObject && newLogObject.LogId) {
                                                oldLogObject.logId = newLogObject.LogId;
                                            }

                                            setAnswerObject(oldLogObject);
                                        }
                                        
                                    }
                                }
                                else if (result.status === Status.FAIL) {
                                    globalContext.setNotificationModalMessage("Cannot create note. " + result.message);
                                    globalContext.setNotificationModalActive(true);
                                    setAddNoteLoading(false);
                                }
                                else if (result.status === Status.ERROR) {
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to create note. " + result.message, errorLocation: "/EDPN/UpdateEDPN" });
                                    globalContext.setErrorModalActive(true);
                                    setAddNoteLoading(false);
                                }
                                setIsAutoSaving(false);
                            },
                            (error) => {
                                clearTimeout(timeoutId);
                                if (error.name === "AbortError")
                                {
                                    console.log("Request timed out");
                                    globalContext.setToastMessage("Autosave Failed. Connection timed out.");
                                    globalContext.setToastIcon("Warning");
                                    globalContext.setToastActive(true);
                                }
                                else {
                                    globalContext.setErrorModalProps({
                                        errorMessage: error,
                                        errorLocation: "/EDPN/UpdateEDPN",
                                    });
                                    globalContext.setErrorModalActive(true);
                                }
                                setAddNoteLoading(false);
                                setIsAutoSaving(false);
                            }
                        );
                }
            });
        });
    }


    function ResetAnswerObject() {

        var therapistID: number | null = null;
        if (globalContext.CoreAccount && globalContext.CoreAccount.currentUser && globalContext.CoreAccount.currentUser.therapistID) {
            therapistID = globalContext.CoreAccount.currentUser.therapistID;
        }

        setAnswerObject({
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: edpnContext.selectedClient ? edpnContext.selectedClient.clientId : null,
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: therapistID,
            dpnstatusId: null,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            absent: false,
            absentReasonId: null,
            tardyReasonId: null,
            tardyReason: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            antecendant: null,
            bcbaname: null,
            injury: null,
            scheduleDuration: null,
            dpnactualDuration: null,
            groupId: null,
            lateReasonId: null,
        })

    }

    function UpdateQuestions() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetQuestions?app=DPN";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("raw questions:", result);
                        setQuestions(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetQuestions" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function UpdateNoteComplete() {

        //loop through all answers, if one answer is blank, set allanswerscomplete to false
        let AllAnswersComplete = true;
        if (answerObject && answerObject.answers) {

            //only check required questions
            var RequiredQuestions = flatQuestions.filter(x => x.type != "multirbt"
                && x.type != "bcbapresent"
                && x.type != "bcbaname"
                && x.type != "abaname"
                && x.type != "abapresent"
                && x.type != "peoplename"
                && x.type != "peoplepresent"
                && x.type != "text_abnormal_behaviors"
                && x.type != "text_injury"
                && x.type != "text_participate"
                && x.type != "bool_default_false_abnormal_behaviors"
                && x.type != "bool_default_false_injury"
                && x.type != "bool_default_false_participate"
            );

            //rbt present
            var RBTPresentQuestions = flatQuestions.filter(x => x.type == "rbt");

            answerObject.answers.filter(a =>
                RequiredQuestions.find(y => y.questionId == a.questionId)
                && (!a.choiceValue || a.choiceValue == -1)
                && (!a.numberValue)
                && (!a.textValue || a.textValue.length == 0 || RBTPresentQuestions.find(z => z.questionId == a.questionId && a.textValue == "-1"))
                && (a.boolValue == null)
            ).forEach(z => AllAnswersComplete = false);
        }

        //logic for add log button
        setNoteIncomplete(
            !answerObject ||
            !answerObject.answers ||
            answerObject.answers.length == 0 ||
            !AllAnswersComplete ||
            timeIn == null ||
            timeOut == null
        );
    }


    function SuccessModalClose() {
        NavigateBack();
    }

    function ValidationModalClose() {
    }

    

    const SubmitNoteButton = () => {

        if (addNoteLoading || isAutosaving) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-800">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button disabled={noteIncomplete} onClick={PreSubmissionGrammarCheck} className={"bg-lacblue m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 " + (noteIncomplete ? 'bg-lacblue-600' : '')}><span className="drop-shadow">Submit Note</span></button>)
        }
    }

    if (edpnContext.selectedClient) {

        return (
            <>
                <div className={"ml-auto mr-auto p-2"}>
                    <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                        <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer col-span-2">
                            <div className="flex-1">
                                <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                            </div>
                            <span className="text-xl w-auto ml-3">Back to Logs</span>
                            <div className="flex-1"></div>
                        </div>

                        <ProfilePicture isEditable={false} setProfilePictureModalActive={setProfilePictureModalActive} Client={edpnContext.selectedClient} setSelectedClientForModal={setSelectedClientForModal} />

                        <table>
                            <tbody>
                                <tr>
                                    <td><span className="font-bold">Learner Name:</span></td>
                                    <td><span className="text-bold ml-2">{edpnContext.selectedClient.fullName}</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font-bold">Birthday:</span></td>
                                    <td><span className="text-bold ml-2">{new Date(edpnContext.selectedClient.dob).toLocaleDateString()}</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font-bold">Insurance Provider:</span></td>
                                    <td><span className="text-bold ml-2">{edpnContext.selectedClient.insuranceName}</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font-bold">RBT Required:</span></td>
                                    <td><span className="text-bold ml-2">{edpnContext.selectedClient.rbtrequired == null ? '' : edpnContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                                </tr>
                                <tr className={(parentConsentTelehealth !== true || edpnContext.selectedClient.telehealthAllowed !== true) ? "hidden" : "normal"}>
                                    <td><span className="font-bold">Telehealth Allowed:</span></td>
                                    <td><span className="text-bold ml-2">{edpnContext.selectedClient.telehealthAllowed == null ? '' : edpnContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                                </tr>
                                <tr>
                                    <td><span className="font-bold">Billing Day Schedule:</span></td>
                                    <td><span className="text-bold ml-2">{billingDaySchedule ? new Date(billingDaySchedule.startTime).toLocaleTimeString() + "-" + new Date(billingDaySchedule.endTime).toLocaleTimeString() : "N/A"}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="grid gap-2 grid-cols-12 xl:w-[40rem] w-full m-auto mt-4 mb-8">

                        <div className="w-full xl:col-span-12 col-span-12">
                            <label className="block font-bold" htmlFor="centers">
                                Service Type:
                            </label>
                            <Select isSearchable={false} options={FilteredCPTCodes ? FilteredCPTCodes : undefined} onChange={edpnContext.setSelectedCPTCode} value={edpnContext.selectedCPTCode} />
                        </div>

                        <div className="w-full xl:col-span-12 col-span-12">
                            <label className="block font-bold" htmlFor="centers">
                                Place of Service:
                            </label>
                            <Select isSearchable={false} options={edpnContext.servicePlaces ? edpnContext.servicePlaces : undefined} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={edpnContext.setSelectedServicePlace} value={edpnContext.selectedServicePlace} />
                        </div>
                   

                        <div className="w-full m-auto sm:col-span-6 col-span-12">
                            <label className="block font-bold" htmlFor="search">
                                Time In:
                            </label>
                            <input onBlur={() => { TimeInValidateTime(true, null); edpnContext.setAutosaveFlag(true);}} onChange={event => setTimeInText(event.target.value)} value={timeInText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeInText.length > 0 && !timeIn ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                        </div>

                        <div className="w-full m-auto sm:col-span-6 col-span-12">
                            <label className="block font-bold" htmlFor="search">
                                Time Out:
                            </label>
                            <input onBlur={() => { TimeOutValidateTime(true, null); edpnContext.setAutosaveFlag(true); }} onChange={event => setTimeOutText(event.target.value)} value={timeOutText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeOutText.length > 0 && !timeOut ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                        </div>

                        <div className="w-full xl:col-span-12 col-span-12">
                            <label className="block font-bold" htmlFor="centers">
                                Were there missed hours this session?:
                            </label>
                            <Select isClearable={true} isSearchable={false} options={edpnContext.absentReasons ? edpnContext.absentReasons?.filter(x => x.absent == false) : undefined} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={setSelectedReason} value={selectedReason} />
                        </div>

                        <div className="w-full h-fit col-span-12 grid ">
                            {filteredQuestions && <EDPNQuestionNode EDPNQuestions={filteredQuestions} EDPNParentQuestion={null} setAnswerObject={setAnswerObject} answerObject={answerObject} Therapists={globalContext.therapists} ResetAnswerObject={ResetAnswerObject} AutoSelectQuestionStack={null} FlatQuestions={flatQuestions} />}
                        </div>


                        <div className="w-full col-span-12 flex justify-between">
                            <button onClick={NavigateBack} className="bg-purple w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600 flex-grow-0 mr-2">
                                <span className="drop-shadow">Back</span>
                            </button>
                            <div className="flex-grow"></div>
                            <SubmitNoteButton />
                        </div>

                    </div>

                    <PopperGrammarBoxes GrammarSet={grammarContext.grammarSets} makeCorrection={grammarContext.MakeCorrection}></PopperGrammarBoxes>

                    <ModalNotification Title={"Warning!"} Icon={"Warning"} Body={"Less than 2 Antecedent Strategies are selected. Unable to submit."} Active={antecedentConfirmationModalActive} setActive={setAntecedentConfirmationModalActive} OnModalClose={function noop() { }} />
                    <ModalConfirm Message={submitNoteConfirmationMessage} Active={submitNoteConfirmationModalActive} setActive={setSubmitNoteConfirmationModalActive} OnConfirm={() => AddNote(true)} />
                    <ModalConfirm Message={saveNoteConfirmationMessage} Active={saveNoteConfirmationModalActive} setActive={setSaveNoteConfirmationModalActive} OnConfirm={() => AddNote(false)} />

                    <ModalNotification Title={"Success!"} Body={"Note for " + edpnContext.selectedClient.fullName + " successfully added."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                    <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() { }} />
                    <ModalNotification Title={"Warning!"} Body={validationMessage} Active={validationModalActive} Icon={"Warning"} setActive={setValidationModalActive} OnModalClose={ValidationModalClose} />
                    <ModalConfirm Message={"There are possible grammar mistakes in one of the inputs. Are you sure you want to continue?"} Active={grammarMistakeModalActive} setActive={setGrammarMistakeModalActive} OnConfirm={() => AntecedentStrategiesCheck()} />
                    <ModalNotification Title={"Summary"} Body={edpnContext.exampleMessage} Active={edpnContext.exampleModalActive} Icon={"Info"} setActive={edpnContext.setExampleModalActive} OnModalClose={function noop() { }} />

                </div>
            </>

            

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }
    
};




export function EDPNAddLog() {

    const authRequest = {
        ...loginRequest
    };


    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                    <GrammarContextProvider>
                        <EDPNAddLogContent />
                    </GrammarContextProvider>
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};

