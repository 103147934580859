import { ALL } from 'dns';
import { createContext, useContext, useEffect, useState } from 'react'
import { apiConfig } from '../../authConfig';
import { Loading } from '../../components/Loading';
import { AuthHour, Center, ChoiceOption, Client, ECLAnswer, ECLAnswerObject, Pod, TherapistOption, CPTCode } from '../../utils/Definitions';
import { GlobalContext } from '../../utils/GlobalContext';

//props for global context
type ECLContextProviderProps = {
    children: React.ReactNode
}

//definition of export type
type ECLContextType = {
    selectedClient: Client | undefined;
    setSelectedClient: any;
    answers: ECLAnswer[] | undefined;
    setAnswers: any;
    selectedCenter: Center | null;
    setSelectedCenter: React.Dispatch<React.SetStateAction<Center | null>>;
    selectedPod: Pod | null;
    setSelectedPod: React.Dispatch<React.SetStateAction<Pod | null>>;
    selectedEditAnswer: ECLAnswerObject | null;
    setSelectedEditAnswer: React.Dispatch<React.SetStateAction<ECLAnswerObject | null>>;
    getAllClients: boolean;
    setGetAllClients: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRBTs: TherapistOption[];
    setSelectedRBTs: React.Dispatch<React.SetStateAction<TherapistOption[]>>;
    allChoiceOptions: ChoiceOption[] | null;
    isViewOnly: boolean;
    setIsViewOnly: React.Dispatch<React.SetStateAction<boolean>>;
    UpdateAuthHours: any;
    authHours: AuthHour[];
    setAuthHours: React.Dispatch<React.SetStateAction<AuthHour[]>>;
    loadingAuth: boolean;
    CPTCodes: CPTCode[] | null;
}

//initilize context
export const ECLContext = createContext<ECLContextType>({
    selectedClient: undefined,
    setSelectedClient: function noop() { },
    answers: undefined,
    setAnswers: function noop() { },
    selectedCenter: null,
    setSelectedCenter: function noop() { },
    selectedPod: null,
    setSelectedPod: function noop() { },
    selectedEditAnswer: null,
    setSelectedEditAnswer: function noop() { },
    getAllClients: false,
    setGetAllClients: function noop() { },
    selectedRBTs: [],
    CPTCodes: [],
    setSelectedRBTs: function noop() { },
    allChoiceOptions: null,
    isViewOnly: false,
    setIsViewOnly: function noop() { },
    UpdateAuthHours: function noop() { },
    authHours: [],
    setAuthHours: function noop() { },
    loadingAuth: false,
});


export const ECLContextProvider = ({ children, }: ECLContextProviderProps) => {

    const globalContext = useContext(GlobalContext);


    //account state
    const [selectedClient, setSelectedClient] = useState<Client>();

    //account state
    const [selectedCenter, setSelectedCenter] = useState<Center | null>(null);
    const [selectedPod, setSelectedPod] = useState<Pod | null>(null);
    const [selectedRBTs, setSelectedRBTs] = useState<TherapistOption[]>([]);


    const [getAllClients, setGetAllClients] = useState<boolean>(false);

    const [allChoiceOptions, setAllChoiceOptions] = useState<ChoiceOption[] | null>(null);

    const [CPTCodes, setCPTCodes] = useState<CPTCode[] | null>(null);

    //answer object
    const [answers, setAnswers] = useState<ECLAnswer[]>();

    //selected edit answer
    const [selectedEditAnswer, setSelectedEditAnswer] = useState<ECLAnswerObject | null>(null);

    const [isViewOnly, setIsViewOnly] = useState<boolean>(false);

    const [authHours, setAuthHours] = useState<AuthHour[]>([]);
    const [loadingAuth, setLoadingAuth] = useState<boolean>(false);

    //useEffect(() => {
    //    if (selectedEditAnswer) {
    //        console.log("selected for edit:", selectedEditAnswer);
    //    }
    //}, [selectedEditAnswer]);

    useEffect(() => {
        if (allChoiceOptions == null || allChoiceOptions.length == 0) {
            UpdateAllChoiceOptions();
        }
        UpdateCPTCodes();
    }, []);

    useEffect(() => {
        setAuthHours([]);
    }, [selectedClient]);

    function UpdateAllChoiceOptions() {
        globalContext.GetToken().then((token: any) => {
            if (token) {

                if (globalContext.CoreAccount.roles.length > 0) {

                    var headers = new Headers();

                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    var options = {
                        method: "GET",
                        headers: headers
                    };

                    var url = apiConfig.apiEndpoint + "/ECL/GetAllChoices";

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                setAllChoiceOptions(result);
                            },
                            (error) => {
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/ECL/GetAllChoices" });
                                globalContext.setErrorModalActive(true);
                            }
                        )
                }
            }
            
        })
    }

    function UpdateAuthHours(CPTCode: string) {

        setLoadingAuth(true);
        setAuthHours([]);
        globalContext.GetToken().then((token: any) => {
            if (token) {

                if (globalContext.CoreAccount.roles.length > 0) {



                    var headers = new Headers();

                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    var options = {
                        method: "GET",
                        headers: headers
                    };

                    var url = apiConfig.apiEndpoint + "/Clients/GetAuthHours?ClientID=" + selectedClient?.clientId + "&CPTCode=" + CPTCode;

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                console.log("result: ", result);
                                setAuthHours(result);
                                setLoadingAuth(false);
                            },
                            (error) => {
                                setLoadingAuth(false);
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetAuthHours" });
                                globalContext.setErrorModalActive(true);
                            }
                        )
                }
                else {
                    setLoadingAuth(false);
                }
            }
            else {
                setLoadingAuth(false);
            }

        })
    }

    function UpdateCPTCodes() {

        globalContext.GetToken().then((token: any) => {

            if (token) {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Admin/GetCPTCodes?app=DPN";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("edpnContext UpdateCPTCODES raw:", result);

                            setCPTCodes(result);

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetCPTCodes" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            }
        })
    }

    //initilize object that is accessible globally
    const ECLContextExport: ECLContextType = {
        selectedClient: selectedClient,
        setSelectedClient: setSelectedClient,
        answers: answers,
        setAnswers: setAnswers,
        selectedCenter: selectedCenter,
        setSelectedCenter: setSelectedCenter,
        selectedPod: selectedPod,
        setSelectedPod: setSelectedPod,
        selectedEditAnswer: selectedEditAnswer,
        setSelectedEditAnswer: setSelectedEditAnswer,
        getAllClients: getAllClients,
        setGetAllClients: setGetAllClients,
        selectedRBTs: selectedRBTs,
        setSelectedRBTs: setSelectedRBTs,
        allChoiceOptions: allChoiceOptions,
        isViewOnly: isViewOnly,
        setIsViewOnly: setIsViewOnly,
        UpdateAuthHours: UpdateAuthHours,
        authHours: authHours,
        setAuthHours: setAuthHours,
        loadingAuth: loadingAuth,
        CPTCodes: CPTCodes,
    };

    return <ECLContext.Provider value={ECLContextExport}>{children}</ECLContext.Provider>;
}