import { createContext, SetStateAction, useEffect, useState } from 'react'
import { useMsal, useAccount } from "@azure/msal-react";
import { apiConfig, loginRequest } from '../authConfig';
import { act } from 'react-dom/test-utils';
import { getRoles } from '@testing-library/react';
import { Center, ChoiceOption, CurrentUser, ErrorModalProps, Pod, Therapist, TherapistData, TherapistSmallObject } from './Definitions';
import { AccountInfo } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';
import { hasRestParameter } from 'typescript';

//props for global context
type GlobalContextProviderProps = {
    children: React.ReactNode
}

//definition of export type
type GlobalContextType = {
    CoreAccount: CoreAccount;
    CheckRoles: any;
    GetCurrentUser: any;
    GetToken: any;
    errorModalActive: boolean;
    setErrorModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    errorModalProps: ErrorModalProps;
    setErrorModalProps: React.Dispatch<React.SetStateAction<ErrorModalProps>>;
    activeAccount: AccountInfo | null;
    UpdateTherapistData: any;
    selectedNavigationTab: string;
    centers: Center[];
    centerChoices: ChoiceOption[];
    lockdownCenters: Center[];
    lockdownCenterChoices: ChoiceOption[];
    pods: Pod[];
    podChoices: ChoiceOption[];
    therapists: TherapistSmallObject[];
    bcbas: TherapistSmallObject[];
    notificationModalActive: boolean;
    setNotificationModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    notificationModalMessage: string | null;
    setNotificationModalMessage: React.Dispatch<React.SetStateAction<string | null>>;
    GetRandomGuid: any;
    setToastMessage: React.Dispatch<React.SetStateAction<string>>;
    toastMessage: string;
    toastActive: boolean;
    setToastActive: React.Dispatch<React.SetStateAction<boolean>>;
    setToastIcon: React.Dispatch<React.SetStateAction<"Success" | "Warning">>;
    toastIcon: string;
}

//account type
interface CoreAccount {
    username: string;
    name: string;
    token: string;
    roles: string[];
    currentUser: CurrentUser | null;
    therapistData: TherapistData | null;
}

//initilize context
export const GlobalContext = createContext<GlobalContextType>({
    CoreAccount: { username: '', name: '', token: '', roles: [], currentUser: null, therapistData: null },
    CheckRoles: function noop() { },
    GetCurrentUser: function noop() { },
    GetToken: function noop() { },
    errorModalActive: false,
    setErrorModalActive: function noop() { },
    errorModalProps: { errorLocation: "", errorMessage: "" },
    setErrorModalProps: function noop() { },
    activeAccount: null,
    UpdateTherapistData: function noop() { },
    selectedNavigationTab: "",
    centers: [],
    centerChoices: [],
    lockdownCenters: [],
    lockdownCenterChoices: [],
    pods: [],
    podChoices: [],
    therapists: [],
    bcbas: [],
    notificationModalActive: false,
    setNotificationModalActive: function noop() { },
    notificationModalMessage: "",
    setNotificationModalMessage: function noop() { },
    GetRandomGuid: function noop() { },
    setToastMessage: function noop() { },
    toastMessage: "",
    toastActive: false,
    setToastActive: function noop() { },
    setToastIcon: function noop() { },
    toastIcon: "Success",

});


export const GlobalContextProvider = ({ children, }: GlobalContextProviderProps) => {
    //msal
    const { instance, accounts } = useMsal();
    const activeAccount = useAccount(accounts[0] || {});

    //account state
    const [coreAccount, setCoreAccount] = useState<CoreAccount>({ username: '', name: '', token: '', roles: [], currentUser: null, therapistData: null });

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorModalProps, setErrorModalProps] = useState<ErrorModalProps>({ errorMessage: "", errorLocation: "" });

    const [notificationModalActive, setNotificationModalActive] = useState<boolean>(false);
    const [notificationModalMessage, setNotificationModalMessage] = useState<string | null>("");

    const [selectedNavigationTab, setSelectedNavigationTab] = useState<string>("");
    const location = useLocation();

    //generic variables
    const [centers, setCenters] = useState<Center[]>([]);
    const [centerChoices, setCenterChoices] = useState<ChoiceOption[]>([]);

    const [lockdownCenters, setLockdownCenters] = useState<Center[]>([]);
    const [lockdownCenterChoices, setLockdownCenterChoices] = useState<ChoiceOption[]>([]);

    const [pods, setPods] = useState<Pod[]>([]);
    const [podChoices, setPodChoices] = useState<ChoiceOption[]>([]);

    const [therapists, setTherapists] = useState<TherapistSmallObject[]>([]);
    const [bcbas, setBCBAs] = useState<TherapistSmallObject[]>([]);

    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastIcon, setToastIcon] = useState<"Success" | "Warning">("Success");
    const [toastActive, setToastActive] = useState<boolean>(false);

    //DONT CHANGE THIS! this is auto updated by the pipeline to match the current database version
    const CurrentWebsiteVersion: number = 63

    // on load get centers, pods and therapists
    useEffect(() => {
        if (coreAccount.token) {
            if (centers.length == 0) {
                UpdateCenters(coreAccount.token);
            }

            if (lockdownCenters.length == 0) {
                UpdateLockdownCenters(coreAccount.token);
            }

            if (pods.length == 0) {
                UpdatePods(coreAccount.token);
            }

            if (therapists.length == 0) {
                UpdateTherapists(coreAccount.token);
            }

            if (bcbas.length == 0) {
                UpdateBCBAs(coreAccount.token);
            }
        }
        
    }, [coreAccount.token]);

    //when msal active account changes, update account
    useEffect(() => {
        if (coreAccount) {
            console.log("Roles:", coreAccount.roles);
        }
    }, [coreAccount]);

    //cache refresh
    function CacheRefresh() {

        //ignore cache refresh if locally ran.
        if (CurrentWebsiteVersion !== 0) {

            //get auth token
            GetToken().then((token) => {

                if (token) {
                    var headers = new Headers();
                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    var options = {
                        method: "GET",
                        headers: headers
                    };

                    var url = apiConfig.apiEndpoint + "/Admin/GetVersionMismatchNew?version=" + CurrentWebsiteVersion;
                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                console.log("mismatch:", result);
                                if (result === true) {
                                    console.log("force refresh");
                                    window.location.reload();
                                }
                            },
                            (error) => {
                                console.log(error);
                                setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetVersionMismatchNew" });
                                setErrorModalActive(true);
                            }
                        )
                }
            })
        }
    }

    useEffect(() => {
        if (centers) {
            const mapped: ChoiceOption[] = centers.map((center: Center) => ({
                value: center.centerId.toString(),
                label: center.centerName
            }));

            setCenterChoices(mapped);
        }
    }, [centers]);

    useEffect(() => {
        if (lockdownCenters) {
            const mapped: ChoiceOption[] = lockdownCenters.map((center: Center) => ({
                value: center.centerId.toString(),
                label: center.centerName
            }));

            setLockdownCenterChoices(mapped);
        }
    }, [lockdownCenters]);

    useEffect(() => {
        if (pods) {
            const mapped: ChoiceOption[] = pods.map((pod: Pod) => ({
                value: pod.podId.toString(),
                label: pod.centerName + " (" + pod.podname + ")",
            }));

            // Sort the mapped array by label
            mapped.sort((a, b) => a.label.localeCompare(b.label));

            setPodChoices(mapped);
        }
    }, [pods]);
        
    

    useEffect(() => {

        //dispay version
        console.log("Current Web Version:", CurrentWebsiteVersion);

        //Version mismatch
        CacheRefresh();


        //UPDATE NAVBAR TAB SELECTED
        const regex = "(?!\/)([^\/]*)";
        const match = location.pathname.match(regex);

        if (match && match[0]) {

            switch (match[0]) {
                case "ecl": {
                    setSelectedNavigationTab("ecl");
                    break;
                }
                case "edpn": {
                    setSelectedNavigationTab("edpn");
                    break;
                }
                case "outcomes": {
                    setSelectedNavigationTab("outcomes");
                    break;
                }
                case "credentials": {
                    setSelectedNavigationTab("credentials");
                    break;
                }
                case "authorizations": {
                    setSelectedNavigationTab("authorizations");
                    break;
                }
                default: {
                    setSelectedNavigationTab("");
                    break;
                }
            }

        }
        else {
            setSelectedNavigationTab("");
        }
        
    }, [location]);

    async function GetRandomGuid() {
        var headers = new Headers();
        var bearer = "Bearer " + coreAccount.token;
        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        var url = apiConfig.apiEndpoint + "/Admin/GetRandomGuid";
        const response = await fetch(url, options);

        return await response.json();
    }

    //get auth token from microsoft
    async function GetToken() {
        const timeout = 10000; // 10 seconds
        let token = coreAccount.token;

        if (activeAccount) {
            try {
                token = await Promise.race([
                    instance.acquireTokenSilent({
                        ...loginRequest,
                        account: activeAccount
                    }).then((response: { accessToken: any; }) => response.accessToken),
                    new Promise<string>((_, reject) => setTimeout(() => reject(new Error("Timeout")), timeout))
                ]);

                setCoreAccount({ ...coreAccount, token: token });
                
            } catch (error) {
                console.error("Error acquiring token or timeout occurred:", error);
            }
        }

        return token;
    }


    //get roles from api server
    async function GetRoles(token: string) {
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        const response = await fetch(apiConfig.apiEndpoint + "/Admin/GetRoles", options);
        return await response.json();
    }

    //get current user from api server
    async function GetCurrentUser(token: string) {
        
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        const response = await fetch(apiConfig.apiEndpoint + "/Admin/GetCurrentUser", options);
        var currentuser: CurrentUser = await response.json();
        console.log("current user:", currentuser);
        return currentuser;
    }

    //get therapist data from api server
    async function GetTherapistData(token: string, therapistID: number) {

        if (therapistID == null) {
            return null;
        }

        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        const response = await fetch(apiConfig.apiEndpoint + "/Admin/GetTherapistCredentials?id=" + therapistID, options);
        var therapistdata: TherapistData = await response.json();
        return therapistdata;
    }

    //when msal active account changes, update account
    useEffect(() => {
        if (activeAccount && activeAccount.username && activeAccount.name) {

            //get auth token
            GetToken().then((token) => {

                if (token) {
                    //using auth token get roles
                    GetRoles(token).then(roles => {

                        //using token get current user
                        GetCurrentUser(token).then((currentuser: any) => {

                            //get therapist data from therapist id
                            GetTherapistData(token, currentuser?.therapistID).then(therapistdata => {

                                //set account object
                                setCoreAccount({
                                    username: activeAccount.username,
                                    name: activeAccount.name ? activeAccount.name : '',
                                    token: token,
                                    roles: roles,
                                    currentUser: currentuser,
                                    therapistData: therapistdata
                                });
                            })
                        })
                    })
                }
            })
        }
    }, [activeAccount]);

    //checks if current user has at least one of the required roles
    function CheckRoles(RequiredRoles: string[]) {

        if (coreAccount.roles.includes('ADMIN')) {
            return true;
        }
        else {
            var HasRequiredRoles = false;

            coreAccount.roles.forEach(function (role) {
                if (RequiredRoles.includes(role)) {
                    HasRequiredRoles = true;
                }
            });

            return HasRequiredRoles;
        }
    }

    function UpdateTherapistData() {
        if (coreAccount && coreAccount.currentUser && coreAccount.currentUser.therapistID) {

            GetToken().then((token: any) => {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Admin/GetTherapistCredentials?id=" + coreAccount.currentUser?.therapistID;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setCoreAccount({
                                ...coreAccount, therapistData: result
                            });
                        },
                        (error) => {
                            console.log(error);
                            setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetTherapistCredentials" });
                            setErrorModalActive(true);
                        }
                    )
            })
        }
    }

    

    function UpdatePods(token: string) {

        if (coreAccount.roles.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Pods/GetAllPods", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setPods(result);
                    },
                    (error) => {
                        setErrorModalProps({ errorMessage: error, errorLocation: "/Pods/GetAllPods" });
                        setErrorModalActive(true);
                    }
                )
        }
    }

    function UpdateCenters(token: string) {
        if (coreAccount.roles.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetCenters", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("updated global centers:", result);
                        setCenters(result);
                    },
                    (error) => {
                        setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetCenters" });
                        setErrorModalActive(true);
                    }
                )
        }
    }

    function UpdateLockdownCenters(token: string) {
        if (coreAccount.roles.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = "";
            if (coreAccount.therapistData && coreAccount.therapistData?.badge) {
                url = apiConfig.apiEndpoint + "/Centers/GetLockdownCenters?ein=" + coreAccount.therapistData?.badge;
            }
            else {
                url = apiConfig.apiEndpoint + "/Centers/GetLockdownCenters";
            }

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("updated global lockdown centers:", result);
                        setLockdownCenters(result);
                    },
                    (error) => {
                        setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetLockdownCenters" });
                        setErrorModalActive(true);
                    }
                )
        }
    }

    function UpdateTherapists(token: string) {

        if (coreAccount.roles.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetAllTherapists", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setTherapists(result);
                    },
                    (error) => {
                        setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllTherapists" });
                        setErrorModalActive(true);
                    }
                )
        }
    }

    function UpdateBCBAs(token: string) {

        if (coreAccount.roles.length > 0) {
            var headers = new Headers();
            var bearer = "Bearer " + token;

            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            fetch(apiConfig.apiEndpoint + "/Centers/GetAllBCBAs", options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setBCBAs(result);
                    },
                    (error) => {
                        setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllBCBAs" });
                        setErrorModalActive(true);
                    }
                )
        }
    }

    //initilize object that is accessible globally
    const GlobalContextExport: GlobalContextType = {
        CoreAccount: coreAccount,
        CheckRoles: CheckRoles,
        GetCurrentUser: GetCurrentUser,
        GetToken: GetToken,
        errorModalActive: errorModalActive,
        setErrorModalActive: setErrorModalActive,
        errorModalProps: errorModalProps,
        setErrorModalProps: setErrorModalProps,
        activeAccount: activeAccount,
        UpdateTherapistData: UpdateTherapistData,
        selectedNavigationTab: selectedNavigationTab,
        centers: centers,
        centerChoices: centerChoices,
        lockdownCenters: lockdownCenters,
        lockdownCenterChoices: lockdownCenterChoices,
        pods: pods,
        podChoices: podChoices,
        therapists: therapists,
        bcbas: bcbas,
        notificationModalActive: notificationModalActive,
        setNotificationModalActive: setNotificationModalActive,
        notificationModalMessage: notificationModalMessage,
        setNotificationModalMessage: setNotificationModalMessage,
        GetRandomGuid: GetRandomGuid,
        setToastMessage: setToastMessage,
        toastMessage: toastMessage,
        toastActive: toastActive,
        setToastActive: setToastActive,
        setToastIcon: setToastIcon,
        toastIcon: toastIcon,
    };

    return <GlobalContext.Provider value={GlobalContextExport}>{children}</GlobalContext.Provider>;
}
