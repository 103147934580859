import React from 'react';

export interface Center {
    centerId: number;
    centerName: string;
    regionId: number;
    regionName: string;
    division: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    county: string;
    country: string;
    active: boolean;
    targetCapacity?: number;
    sqft?: number;
    openDate: Date;
    statusId: number;
    centerStatus: string;
    createdOn: Date;
    updatedOn: Date;
    updatedBy: string;
    timezone: string;
}

export interface Pod {
    podId: number;
    podname: string;
    poddescription: string;
    podLocation: string;
    centerName: string;
    centerId: number;
    regionId: number;
    regionName: string;
}

export interface Client {
    active: boolean;
    centerId: number;
    centerName: string;
    clientId: number;
    companyInsuranceId: number;
    displayName: string;
    dob: Date;
    firstName: string;
    fullName: string;
    hipaaname: string;
    insuranceName: string;
    isIamedicaid: number;
    isIlmedicaid: number;
    isInmedicaid: number;
    isMimedicaid: number;
    logsCount: number;
    notesCount: number;
    notesTime: number;
    logsTime: number;
    lastName: string;
    placeOfService: string;
    placeOfServiceId: number;
    podDescription: string;
    podId: number;
    podName: string;
    regionId: number;
    regionName: string;
    rowNumber: number;
    startDate: Date;
    therapySchedule: string;
    therapyScheduleId: number;
    absent: number;
    telehealthAllowed: boolean | null;
    rbtrequired: boolean | null;
    parentalConsentTelehealth: boolean | null;
}

export interface NewClient {
    active: boolean;
    centerId: number;
    centerName: string;
    id: number;
    companyInsuranceId: number;
    displayName: string;
    dob: Date;
    firstName: string;
    fullName: string;
    hipaaname: string;
    insuranceName: string;
    isIamedicaid: number;
    isIlmedicaid: number;
    isInmedicaid: number;
    isMimedicaid: number;
    logsCount: number;
    notesCount: number;
    notesTime: number;
    logsTime: number;
    lastName: string;
    placeOfService: string;
    placeOfServiceId: number;
    podDescription: string;
    podId: number;
    podName: string;
    regionId: number;
    regionName: string;
    rowNumber: number;
    startDate: Date;
    therapySchedule: string;
    therapyScheduleId: number;
    absent: number;
    contacts: ClientContact[] | null;
    payorInfos: ClientPayorInfo[] | null;
}

export interface ClientContact {
    contactId: number;
    clientId: number;
    firstName: string | null;
    lastName: string | null;
    streetAddress: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    phoneNumber: string | null;
    emailAddress: string | null;
    contactType: string | null;
    relationshipId: number | null;
}

export interface ClientPayorInfo {
    clientPayorInfoId: number;
    clientId: number;
    payorInfoTypeId: number;
    subscriberFirstName: string;
    subscriberMiddleName: string | null;
    subscriberLastName: string;
    subscriberDob: Date;
    subscriberAddress: string;
    subscriberAddress2: string | null;
    subscriberCity: string;
    subscriberState: string;
    subscriberZipCode: string;
    payorId: number;
    subscriberIdNumber: string;
    fundingTypeId: number;
    copay: number | null;
    coinsurance: string | null;
    deductible: number | null;
    outOfPocketMax: number | null;
    effectiveDate: Date | null;
    benefitPeriodStart: string | null;
    benefitPeriodEnd: string | null;
}

export interface PageState {
    state: "home" | "logs" | "add";
}

export interface ECLQuestion {
    children: ECLQuestion[];
    value: number;
    label: string;
    questionId: number;
    parentQuestionId?: number;
    questionText: string;
    cptcode: string;
    type: string;
    active: boolean;
    app: string;
    ecldpnchoices: any[];
    narrativeText?: string;
}

export interface ECLAnswerObject {
    answers: ECLAnswer[] | null;
    logId: string | null;
    startTime: Date | null;
    endTime: Date | null;
    createdDate: Date | null;
    approvedDate: Date | null;
    clientId: number | null;
    cptcode: string | null;
    narrative: string | null;
    billingDate: Date | null;
    therapistId: number | null;
    dpnstatusId: number | null;
    active: boolean | null;
    harvested: boolean | null;
    client: any | null;
    dpnstatus: any | null;
    therapist: any | null;
    ecldpnanswers: any[] | null;
    placeOfServiceId: number | null;
    centerId: number | null;
    updatedOn: Date | null;
    updatedBy: string | null;
    approvedById: number | null;
    podId: number | null;
    clientName: string | null;
    comments: ECLComment[] | null;
    modeOfServiceId: number | null;
    isIamedicaid: number | null;
    isIlmedicaid: number | null;
    isInmedicaid: number | null;
    isMimedicaid: number | null;
    logSetId: number | null;
    timezone: string | null;
    showApproveButton: boolean | null;
    companyInsuranceId: number | null;
    therapyScheduleId: number | null;
    therapySchedule: string | null;
    lateReasonId: number | null;
}


export interface ECLAnswer {
    answerId: number | null;
    questionId: number | null;
    multiIndex: number | null;
    logId: string | null;
    textValue: string | null;
    numberValue: number | null;
    boolValue: boolean | null;
    choiceValue: number | null;
    log: string | null;
    question: any | null;
}


export interface Therapist {
    therapistId: number;
    employeeName: string;
    badge: number;
    jobFunction: string;
    podId: number;
    status: string;
    centerId: number;
    centerName: string;
    podname: string;
    poddescription: string;
    regionId: number;
    regionName: string;
    bcbarecertDate: Date | null;
}

export interface TherapistData {
    therapistId: number;
    employeeName: string;
    badge: number;
    credentials: string;
    jobFunction: string;
    podId: number;
    status: string;
    centerId: number;
    centerName: string;
    podname: string;
    poddescription: string;
    regionId: number;
    regionName: string;
    signatureImage: Uint8Array;
}

export interface TherapistSmallObject {
    therapistId: number;
    employeeName: string;
}

export interface CurrentUser {
    name?: string;
    jobTitle?: string;
    department?: string;
    therapistID?: number;
    podID?: number;
    centerID?: number;
    bcba: boolean;
}

export interface ErrorModalProps {
    errorMessage?: string | null;
    errorLocation?: string;
}

export interface CPTCode {
    value: string;
    label: string;
}

export interface TherapistOption {
    value: string;
    label: string;
}

export interface ChoiceOption {
    value: string;
    label: string;
}

export interface ECLComment {
    dpncommentId: number;
    clientId: number;
    fullName: string;
    dateTime: Date;
    comment?: string;
    createdBy?: string;
    createdOn: Date;
    billingDateFilter: number;
}

export interface ECLSet {
    LogSetID: number;
    LogRecentLogDate: Date;
    Logs: ECLAnswerObject[];
}

export interface VwOutcomesQuestion {
    questionId: number;
    questionText: string;
    questionType: string;
    sortOrder: string;
    groupId: number;
    metricCategory: string;
    metricSubCategory: string;
    evaluationsTypeId: number;
    nullable: string;
}

export interface OutcomesQuestion {
    question: VwOutcomesQuestion;
    choices: ChoiceOption[];
}

export interface OutcomesGroup {
    groupId: number;
    groupText: string;
    outcomesQuestions: OutcomesQuestion[];
}

export interface OutcomeEvaluation {
    evaluation: OutcomesEvaluation;
    answers: OutcomeEvaluationAnswer[];
    nullables: OutcomeEvaluationAnswer[];
    validations: OutcomeEvaluationAnswer[];
}

export interface OutcomesEvaluation {
    outcomesEvaluationsId: number | null;
    evaluationsTypeId: number;
    dateCreated: Date;
    dateUpdated: Date;
    dateCompleted: Date | null;
    clientId: number;
    sourceTypeId: number;
}

export interface OutcomeEvaluationAnswer {
    value: string | null;
    questionID: number;
}

export interface VwOutcomesEvaluation {
    outcomesEvaluationsId: string;
    evaluationTypeId: number;
    dateCreated: Date;
    dateUpdated: Date;
    dateCompleted: Date;
    clientId: number;
    sourceTypeId: number;
    evaluationsType: string;
}

export interface VwPayorsPerCenter {
    centerId: number;
    payorId: number;
    payorName: string;
    statusId: number;
}

export interface TherapistCredentials {
    therapistID: number;
    payorId: number;
    centerId: number;
    credentialed: boolean;
    credentialedDate: Date;
}

export interface CredentialsTherapistPayor {
    therapistId: number;
    payorId: number;
    payorName: string;
    centerId: number;
    credentialed: boolean;
    credentialedDate: Date;
}

export interface TherapistCredentialNote {
    noteId: number;
    therapistId: number;
    statusId: number;
    notes: string;
    noteDate: Date;
    statusDescription: string;
}

export interface CredentialNoteStatus {
    statusId: number;
    status: string;
    value: number;
    label: string;
}

export interface TherapistCredentialStatus {
    therapistId: number;
    centerId: number;
    credentialed: boolean;
}

export interface BCBATherapist {
    therapistId: number;
    employeeName: string;
    bcbarecertDate: Date | null;
    jobTitle: string;
}

export interface RbtcredentialState {
    state: string;
    active: boolean;
}


export interface EDPNQuestion {
    children: EDPNQuestion[];
    value: number;
    label: string;
    questionId: number;
    parentQuestionId?: number;
    questionText: string;
    cptcode: string;
    type: string;
    active: boolean;
    app: string;
    ecldpnchoices: any[];
    narrativeText?: string;
    defaultText?: string;
    exampleText?: string;
}

export interface EDPNAnswerObject {
    answers: EDPNAnswer[] | null;
    logId: string | null;
    startTime: Date | null;
    endTime: Date | null;
    createdDate: Date | null;
    approvedDate: Date | null;
    clientId: number | null;
    cptcode: string | null;
    narrative: string | null;
    billingDate: Date | null;
    therapistId: number | null;
    dpnstatusId: number | null;
    active: boolean | null;
    harvested: boolean | null;
    client: any | null;
    dpnstatus: any | null;
    therapist: any | null;
    ecldpnanswers: any[] | null;
    placeOfServiceId: number | null;
    centerId: number | null;
    updatedOn: Date | null;
    updatedBy: string | null;
    approvedById: number | null;
    podId: number | null;
    clientName: string | null;
    comments: EDPNComment[] | null;
    modeOfServiceId: number | null;
    isIamedicaid: number | null;
    isIlmedicaid: number | null;
    isInmedicaid: number | null;
    isMimedicaid: number | null;
    logSetId: number | null;
    timezone: string | null;
    showApproveButton: boolean | null;
    absent: boolean | null;
    absentReasonId: number | null;
    tardyReasonId: number | null;
    tardyReason: string | null;
    companyInsuranceId: number | null;
    therapyScheduleId: number | null;
    therapySchedule: string | null;
    antecendant: string | null;
    bcbaname: string | null;
    injury: boolean | null;
    scheduleDuration: number | null;
    dpnactualDuration: number | null;
    groupId: string | null;
    lateReasonId: number | null;
}


export interface EDPNAnswer {
    answerId: number | null;
    questionId: number | null;
    multiIndex: number | null;
    logId: string | null;
    textValue: string | null;
    numberValue: number | null;
    boolValue: boolean | null;
    choiceValue: number | null;
    log: string | null;
    question: any | null;
}

export interface EDPNComment {
    dpncommentId: number;
    clientId: number;
    fullName: string;
    billingDate: Date;
    comment?: string;
    createdBy?: string;
    createdOn: Date;
    billingDateFilter: number;
    refId: string | null;
}

export interface EDPNSet {
    LogSetID: number;
    LogRecentLogDate: Date;
    Logs: EDPNAnswerObject[];
}

export interface ClientAllergyObject {
    clientID: number | undefined;
    allergies: string;
}

export interface FutureTimeObject {
    podID: number;
    time: Date;
}

export interface AbsentReason {
    absent: boolean;
    excused: boolean;
    id: number;
    planned: boolean;
    tardyReason: string;
}

export interface ClientComment {
    dpncommentId?: number;
    clientId: number;
    billingDate?: Date;
    comment: string;
    createdOn?: Date;
    createdBy?: string;
    updatedOn?: Date;
    refId: string | null;
}

export interface AuthorizedBCBAObject {
    bcba: string;
    clientId: number;
}

export interface ApprovalMetrics {
    yesterday: Date;
    yesterdayActiveLearners: number;
    yesterdayLearnersWithLogs: number;
    yesterdayAbsent: number;
    yesterdayRemaining: number;
    today: Date;
    todayActiveLearners: number;
    todayLearnersWithLogs: number;
    todayAbsent: number;
    todayRemaining: number;
}

// Define the enum for status
export enum Status {
    SUCCESS,
    FAIL,
    ERROR
}

export interface APIReturnObject {
    status: Status;
    message: string | null;
}


export interface authorizedBcba {
    authorizedBcbaid: string | null;
    clientId: number;
    therapistId: number;
    effectiveStart: Date;
    active: boolean;
}

export interface GrammarItem {
    originalText: string;
    message: string;
    replacements: string[];
    replacementOffset: number;
    replacementLength: number;
}

export interface GrammarSet {
    grammarDivId: string;
    grammarText: string;
    needsUpdating: boolean;
    grammarItems: GrammarItem[];
}

export interface ScheduleEvent {
    id: number;
    resourceId: number;
    start: Date;
    startHour: number;
    startMinute: number;
    end: Date;
    endHour: number;
    endMinute: number;
}

export interface Schedule {
    scheduleId: number;
    therapyScheduleId: number;
    dayOfWeekId: number;
    startTime: Date;
    endTime: Date;
    fte: number;
    description: string;
}

export interface LearnerInfo {
    clientID?: number;
    centerName: string;
    learnerFirstName: string;
    learnerLastName: string;
    learnerMiddleName: string;
    learnerDOB: Date | null;
    learnerAddress: string;
    learnerAddress2: string;
    learnerCity: string;
    learnerState: string;
    learnerZipCode: string;
    primaryContactFirstName: string;
    primaryContactLastName: string;
    primaryContactAddress: string;
    primaryContactAddress2: string;
    primaryContactCity: string;
    primaryContactState: string;
    primaryContactZipCode: string;
    primaryContactPhoneNumber: string;
    primaryContactEmail: string;
    primaryContactRelationshipID?: number;
    therapyScheduleID: number;

    primarySubscriberFirstName: string;
    primarySubscriberLastName: string;
    primarySubscriberMiddleName: string;
    primarySubscriberAddress: string;
    primarySubscriberAddress2: string;
    primarySubscriberCity: string;
    primarySubscriberState: string;
    primarySubscriberZipCode: string;
    primarySubscriberDOB?: Date | null;

    primaryPayorInsuranceID: number;
    primaryPayorSubscriberID: string;
    primaryPayorFundingTypeID: number;
    primaryCopay?: number;
    primaryCoinsurance: string;
    primaryDeductible?: number;
    primaryOutOfPocketMax?: number;
    primaryEffectiveDate: Date | null;

    primaryBenefitPeriodStart: Date | null;
    primaryBenefitPeriodEnd: Date | null;

    primaryDeductibleType: string;
    primaryOutOfPocketType: string;
    primaryCopayFrequency: string;

    secondarySubscriberFirstName: string;
    secondarySubscriberLastName: string;
    secondarySubscriberMiddleName: string;
    secondarySubscriberAddress: string;
    secondarySubscriberAddress2: string;
    secondarySubscriberCity: string;
    secondarySubscriberState: string;
    secondarySubscriberZipCode: string;
    secondarySubscriberDOB?: Date | null;

    secondaryPayorInsuranceID: number;
    secondaryPayorSubscriberID: string;
    secondaryPayorFundingTypeID: number;
    secondaryCopay?: number;
    secondaryCoinsurance: string;
    secondaryDeductible?: number;
    secondaryOutOfPocketMax?: number;
    secondaryEffectiveDate: Date | null;

    secondaryBenefitPeriodStart: Date | null;
    secondaryBenefitPeriodEnd: Date | null;

    secondaryDeductibleType: string;
    secondaryOutOfPocketType: string;
    secondaryCopayFrequency: string;

    tertiarySubscriberFirstName: string;
    tertiarySubscriberLastName: string;
    tertiarySubscriberMiddleName: string;
    tertiarySubscriberAddress: string;
    tertiarySubscriberAddress2: string;
    tertiarySubscriberCity: string;
    tertiarySubscriberState: string;
    tertiarySubscriberZipCode: string;
    tertiarySubscriberDOB?: Date | null;

    tertiaryPayorInsuranceID: number;
    tertiaryPayorSubscriberID: string;
    tertiaryPayorFundingTypeID: number;
    tertiaryCopay?: number;
    tertiaryCoinsurance: string;
    tertiaryDeductible?: number;
    tertiaryOutOfPocketMax?: number;
    tertiaryEffectiveDate: Date | null;

    tertiaryBenefitPeriodStart: Date | null;
    tertiaryBenefitPeriodEnd: Date | null;

    tertiaryDeductibleType: string;
    tertiaryOutOfPocketType: string;
    tertiaryCopayFrequency: string;

    learnerStatus: boolean;
    learnerStartDate: Date | null;
    centerID?: number;
    podID: number;
    rbtRequired: boolean;
    telehealthAvailable: boolean;
    lastDayOf97153?: Date | null;
    lastDayOf97153ReasonID?: number;
    lastDayOfService?: Date | null;
    lastDayOfServiceReasonID?: number;
}

export interface VwCenterAllocation {
    firstName: string | null;
    lastName: string | null;
    jobTitle: string | null;
    centerId: number | null;
    therapistId: number;
    credentials: string | null;
    signatureImage: Uint8Array | null;
    bcbacertDate: Date | null;
    dateEffective: Date | null;
    dateEnd: Date | null;
    permanentAllocation: boolean;
    allocationReason: string | null;
    updatedBy: string | null;
    updated: Date | null;
}

export interface AuthHour {
    clientID: number;
    CPTCode: string;
    providerType: string;
    hoursRemaining: number;
    hoursBilledMTD: number;
    status: string;
    updatedOn: Date;
}

export interface VwEzclaimInsuranceAuthorizationsMatched {
    source: string;
    dataIngestDatetimeUtc: Date;
    dataflowLoadDatetimeUtc: Date;
    patientName: string;
    ezclaimPatientId: string;
    authNumber: string;
    procedureCode: string;
    mod1: string;
    mod2: string;
    phyName: string;
    notes: string;
    payerName: string;
    startDate: Date;
    endDate: Date;
    unitsAllowed: number | null;
    unitsRemaining: number | null;
    dollarsAllowed: number | null;
    dollarsRemaining: number | null;
    authStatus: string;
    ezclaimAuthPayerType: string;
    ezclaimAuthPayerElig: string;
    primaryPayer: string;
    primaryPatInsEligStatus: string;
    secondaryPayer: string
    secondaryPatInsEligStatus: string;
    tertiaryPayer: string;
    tertiaryPatInsEligStatus: string;
    authSequenceNumber: number;
    authSequenceNumberAuthLineCount: number | null;
    sourcePatientId: string;
    billingDbclientsLighthouseClientId: string;
    unitsConversion: number | null;
    hoursAllowed: number | null;
    hoursRemaining: number | null;
}

export interface VwAuthorization {
    authId: string;
    reportDueDate: Date;
    statusId: number;
    payorType: string;
    clientId: number;
    bucket: boolean;
    active: boolean;
    firstName: string;
    lastName: string;
    centerName: string;
    dob: Date;
    startDate: Date;
    endDate: Date;
    payorName: string;
    providerName: string;
    authNumber: string;
}

export interface VwAuthorizationDetail {
    authDetailId: string;
    authId: string;
    authNumber: string;
    cptcode: string;
    mod1: string;
    mod2: string;
    providerName: string;
    notes: string;
    payorName: string;
    startDate: Date;
    endDate: Date;
    unitsAllowed: number;
    unitsRequested: number | null;
    payorInfoType: string;
    hoursAllowed: number | null;
    status: string;
    clientId: number;
}

export interface VwCenterAuthorization {
    authId: string;
    reportDueDate: Date;
    clientId: number;
    firstName: string;
    lastName: string;
    centerId: number;
    status: string;
    payorInfoType: string;
    payorName: string;
    authorizedProvider: string;
    assignedProvider: string;
}

export interface VwAuthorizationHistory {
    authHistoryId: string;
    authId: string;
    updatedBy: string;
    updatedOn: Date;
}

export interface VwEzclaimInsuranceAuthorizationsMatched {
    source: string;
    dataIngestDatetimeUtc: Date;
    dataflowLoadDatetimeUtc: Date;
    patientName: string;
    ezclaimPatientId: string;
    authNumber: string;
    procedureCode: string;
    mod1: string;
    mod2: string;
    phyName: string;
    notes: string;
    payerName: string;
    startDate: Date;
    endDate: Date;
    unitsAllowed: number | null;
    unitsRemaining: number | null;
    dollarsAllowed: number | null;
    dollarsRemaining: number | null;
    authStatus: string;
    ezclaimAuthPayerType: string;
    ezclaimAuthPayerElig: string;
    primaryPayer: string;
    primaryPatInsEligStatus: string;
    secondaryPayer: string
    secondaryPatInsEligStatus: string;
    tertiaryPayer: string;
    tertiaryPatInsEligStatus: string;
    authSequenceNumber: number;
    authSequenceNumberAuthLineCount: number | null;
    sourcePatientId: string;
    billingDbclientsLighthouseClientId: string;
    unitsConversion: number | null;
    hoursAllowed: number | null;
    hoursRemaining: number | null;
}

export interface VwAuthorization {
    authId: string;
    reportDueDate: Date;
    statusId: number;
    payorType: string;
    clientId: number;
    bucket: boolean;
    active: boolean;
    firstName: string;
    lastName: string;
    centerName: string;
    dob: Date;
    startDate: Date;
    endDate: Date;
    payorName: string;
    providerName: string;
    authNumber: string;
}

export interface VwAuthorizationDetail {
    authDetailId: string;
    authId: string;
    authNumber: string;
    cptcode: string;
    mod1: string;
    mod2: string;
    providerName: string;
    notes: string;
    payorName: string;
    startDate: Date;
    endDate: Date;
    unitsAllowed: number;
    unitsRequested: number | null;
    payorInfoType: string;
    hoursAllowed: number | null;
    status: string;
    clientId: number;
}

export interface VwCenterAuthorization {
    authId: string;
    reportDueDate: Date;
    clientId: number;
    firstName: string;
    lastName: string;
    centerId: number;
    status: string;
    payorInfoType: string;
    payorName: string;
    authorizedProvider: string;
    assignedProvider: string;
}

export interface VwAuthorizationHistory {
    authHistoryId: string;
    authId: string;
    updatedBy: string;
    updatedOn: Date;
    text: string;
}

export interface PayorInfoType {
    payorInfoTypeId: number;
    payorInfoType1: string;
}

export interface AuthorizationStatus {
    authStatusId: number;
    status: string;
}

export interface VwLateEcldpnreason {
    reasonId: number;
    reason: string;
    sortOrder: number;
    app: string;
}

export interface OptimizedECLEDPN {
    logs: EDPNAnswerObject[] | null;
    comments: EDPNComment[] | null;
}